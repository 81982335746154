<template>
  <div id="PageTable">
    <MyBanner text="" />
    <MyHeaderPage :title="$t('orders.title')" subtitle="" />
    <br />
    <div class="contentPage">
      <div class="mdl-grid" style="align-items: baseline">
        <div class="mdl-cell mdl-cell--4-col mdl-cell--12-col-phone">
          <div class="cdl-input">
            <input type="date" class="not-empty" v-model="dateFrom" />
            <label>{{ $t("filter.from") }}</label>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col mdl-cell--12-col-phone">
          <div class="cdl-input">
            <input type="date" class="not-empty" v-model="dateTo" />
            <label>{{ $t("filter.to") }}</label>
          </div>
        </div>
        <div
          v-if="!IsMobile"
          class="mdl-cell mdl-cell--2-col mdl-cell--2-offset"
        >
          <div class="cdlButtonGreen" style="width: auto">
            <a style="color: white" @click="setFilter()">
              {{ $t("filter.filter") }}
            </a>
          </div>
        </div>
        <div v-else class="mdl-cell mdl-cell--12-col">
          <div class="cdlButtonGreen" style="width: auto">
            <a style="color: white" @click="setFilter()">
              {{ $t("filter.filter") }}
            </a>
          </div>
        </div>
      </div>

      <List
        :list="this.orders"
        :view-loader="this.viewLoader"
        :view-error="this.viewError"
        :total-count="this.total_count"
        @change-page="this.onChangePage"
      />
      <div v-if="generalError != ''">
          <p class="error" style="text-align: center" v-html="generalError"></p>
        </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import moment from "moment";
import { useFilterStore } from "@/stores/FilterStore";
import List from "@/components/Order/List.vue";

export default {
  name: "OrdersPage",
  components: {
    MyBanner,
    MyHeaderPage,
    List,
  },
  data() {
    return {
      IsMobile: this.$IsMobile(),
      dateFrom: moment().subtract(6, "months").format("DD-MM-YYYY"),
      dateTo: moment().format("DD-MM-YYYY"),
      orders: [],
      viewLoader: false,
      viewError: false,
      generalError: ""
    };
  },
  methods: {
    setFilter() {
      const filterStore = useFilterStore();
      filterStore.orders = {
        from: this.dateFrom,
        to: this.dateTo,
      };
      this.getOrders();
    },
    getOrders() {
      // const filterStore = useFilterStore();

      this.viewLoader = false;
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      const self = this;

      this.axios
        .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "sales/v1/order", {
          headers: he,
        })
        .then((response) => {
          this.orders = response.data;
          this.viewLoader = true;
        })
        .catch(function (err) {
          if (err.response.data.message == "invalid token") {
            return self.$router.push({ name: "MyLogin" });
          }
          self.viewLoader = true;
          self.generalError = err.response
            ? err.response.data.message
            : err.message;

        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    const filterStore = useFilterStore();
    if (!filterStore.orders.from || !filterStore.orders.to) {
      this.setFilter();
      return;
    } else if (
      filterStore.orders.from != this.dateFrom ||
      filterStore.orders.to != this.dateTo
    ) {
      this.dateFrom = filterStore.orders.from;
      this.dateTo = filterStore.orders.to;
      this.getOrders();
      return;
    }
    this.setFilter();
  },
};
</script>
